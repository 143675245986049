<template>
  <div>
    <label for="name" class="textarea-label">{{ label }}</label>
    <textarea
        id="text"
        :name="name"
        :rows="rows"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="updateTextarea"
        maxlength="2000"
        class="textarea "
        :class="{ 'border-red-300': validation }"
    />
    <div class="flex">
      <p class="input-notice">В свободной форме</p>
      <span class="input-notice text-right">{{ charactersLeft }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [ String, Number ],
    placeholder: {
      type: String
    },
    rows: {
      type: String,
      default: "3"
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Boolean
    },
    name: {
      type: String,
      default: "desc"
    }
  },
  computed: {
    charactersLeft() {
      const char = this.modelValue.length
      const limit = 2000
      return char + "/" + limit
    }
  },
  methods: {
    updateTextarea(event) {
      this.$emit("update:modelValue", event.target.value)
    }
  },
}
</script>

<style scoped>
.textarea {
  @apply
  whitespace-pre-wrap
  block
  w-full
  rounded-md
  text-gray-900
  p-5
  font-thin
  shadow-sm
  ring-1
  ring-inset
  ring-gray-300
  placeholder-gray-300
  focus:ring-2
  focus:ring-inset
  focus:ring-indigo-600
  sm:py-1.5
  sm:text-sm
  sm:leading-6
  bg-white
  dark:bg-gray-900
}

.textarea-label {
  @apply
  block
  text-sm
  font-normal
  text-gray-700
  dark:text-white
}

.input-notice {
  @apply
  mt-2
  text-xs
  w-full
  text-gray-500
  font-thin
}

.textarea:disabled {
  @apply
  text-gray-300
  bg-gray-50
  cursor-not-allowed
}
</style>